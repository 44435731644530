<template>
	<div class="resources">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back search />
		</div><!-- header -->
		<div class="header-image red"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="FolderIcon"
				title="Latest Resources"
				colour="red"
			/>
			<div class="section-gap section-ports">
				<span class="eyebrow sub">This month</span>
				<div class="card-row-wrap-alt" v-dragscroll>
					<CardRow
                        v-for="resource in current"
                        :key="resource.id"
						eyebrow
						routeName="ResourcesSingle"
                        :id="resource.id"
						class="card-row-block-resources card-row-block-alt"
						:title="resource.title"
						:text="resource.simplified_content"
						:tag="resource.category"
						:type="resource.simplified_type"
					/>
				</div><!-- port card wrap -->
                <div
                    v-for="(resources, date) in all"
                    :key="date"
                    class="section-gap section-ports"
                >
                    <span class="eyebrow sub">{{ date | moment('MMMM YYYY') }}</span>
                    <div class="card-row-wrap">
                        <CardRow
                            v-for="resource in resources"
                            :key="resource.id"
                            routeName="ResourcesSingle"
                            :id="resource.id"
                            class="card-row-block-resources"
                            :title="resource.title"
                            :tag="resource.category"
                            :type="resource.simplified_type"
                        />
                    </div><!-- port card wrap -->
                </div>
			</div>
		</div>
		<ResourceSearchOverlay />
		<ResourceAdviceOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import CardRow from '@/components/CardRow.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import ResourceSearchOverlay from '@/components/ResourceSearchOverlay.vue';
	import ResourceAdviceOverlay from '@/components/ResourceAdviceOverlay.vue';
    import ResourceService from '@/services/ResourceService';
	export default {
		name: 'ResourcesLatest',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			CardRow,
			SectionTitle,
			ResourceSearchOverlay,
			ResourceAdviceOverlay
		},
		data () {
	        return {
                current: [],
                all: []
	        };
	    },
	    methods: {
            getLatestResources () {
                return ResourceService.getLatestResources().then((response) => {
                    this.current = response.data.current;
                    this.all = response.data.all;
                });
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getLatestResources();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
</style>
